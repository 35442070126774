








































































import Vue from "vue";
import { Action } from "vuex-class";
import { HowToVideo } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

@Component({ components: { VideoPlayer } })
export default class VideoItem extends Vue {
  @Prop({ default: () => {} }) video!: HowToVideo;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;
  @Action("howTos/editVideo") editVideo!: Function;
  @Action("howTos/deleteVideo") deleteVideo!: Function;

  dialog = false;
  loading = false;
  editing = false;
  newTitle = this.video.Title;
  newDescription = this.video.Description;

  async edit() {
    const data = {
      videoId: this.video.ID,
      newTitle: this.newTitle,
      newDescription: this.newDescription,
    };
    await this.editVideo(data);
  }

  async remove() {
    this.deleteVideo({ videoId: this.video.ID });
  }
}
